import React, { Component } from 'react';
import { Card, Typography, withStyles } from '@material-ui/core';
import Page from 'components/Page';
import Footer from 'components/Footer';

class P extends Component {
	render() {
		const { children, ...extra } = this.props;
		return (
			<Typography display="inline-block" paragraph {...extra}>
				{children}
			</Typography>
		);
	}
}

class Privacy extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Page title="Merge Customers">
				<Card className={classes.root}>
					<P>
						<Typography variant="h1">Customer Merges</Typography>
						<Typography variant="h5">Last updated June 19, 2025</Typography>
					</P>
					<P>TBD</P>
				</Card>
				<Footer />
			</Page>
		);
	}
}

const styles = (theme) => ({
	root: {
		width: '1024px',
		maxWidth: '100%',
		padding: theme.spacing(3),
		margin: 'auto',
	},
});

export default withStyles(styles)(Privacy);
