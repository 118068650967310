import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Button } from 'components/common';
import * as client from 'api/client';
import CustomerInformationSection from 'modules/Customer/Details/CustomerInformationSection';
import CustomerProjects from 'modules/Customer/Details/CustomerProjects';
import CustomerAvatarModal from 'modules/Customer/Details/CustomerAvatarModal';
import TitleSection from 'components/TitleSection';
import UpdateCustomerDrawer from 'modules/Customer/Update';
import * as ls from 'utils/localStorage';

class CustomerDetails extends Component {
	state = {
		customer: {},
		projects: [],
		loading: true,
		isUpdating: false,
		avatarModalOpen: false,
		customerAvatarEnabled: false,
	};

	componentDidMount = () => {
		const customerId = +this.props.match.params.customerId;

		ls.getCompanyDetailsFromLocalStorage().then((company) => {
			this.setState({ customerAvatarEnabled: company.customerAvatarEnabled });
		});

		client.getCustomerDetails({ customerId }).then((response) => {
			if (response.success) {
				const { customer, projects } = response.data;
				this.setState({ loading: false, customer, projects });
			}
		});
	};

	handleCustomerUpdated = (customer) => {
		this.setState({ customer, isUpdating: false });
	};

	handleOpenAvatarModal = () => {
		this.setState({ avatarModalOpen: true });
	};

	handleCloseAvatarModal = () => {
		this.setState({ avatarModalOpen: false });
	};

	handleAvatarUploaded = (avatar) => {
		this.setState(({ customer }) => ({
			avatarModalOpen: false,
			customer: { ...customer, avatar: avatar },
		}));
	};

	render = () => {
		const { projects, customer, isUpdating, avatarModalOpen, loading } = this.state;

		return loading ? (
			<Box display="flex" width="100%" height="300px" justifyContent="center" alignItems="flex-end">
				<CircularProgress size={60} />
			</Box>
		) : (
			<Box>
				<UpdateCustomerDrawer
					customer={isUpdating && customer}
					onCustomerUpdated={(customer) => this.handleCustomerUpdated(customer)}
					onClose={() => this.setState({ isUpdating: false })}
				/>

				<TitleSection title="Customer Details">
					<Button onClick={() => this.setState({ isUpdating: true })} variant="text" color="primary" height={28}>
						Edit
					</Button>
				</TitleSection>

				{customer.deleted && <Alert severity="error" style={{marginTop: '10px'}}>
					Warning: This customer has been deleted. They will no longer appear in customer
					lists, searches, or other views. You can still access their data directly via this URL.
				</Alert>}

				<CustomerInformationSection
					customer={customer}
					customerAvatarEnabled={this.state.customerAvatarEnabled}
					onAvatarClicked={this.handleOpenAvatarModal}
				/>

				<Box mt="20px">
					<CustomerProjects customer={customer} projects={projects} />
				</Box>

				<CustomerAvatarModal
					customer={customer}
					isOpen={avatarModalOpen}
					onAvatarUploaded={this.handleAvatarUploaded}
					onClose={this.handleCloseAvatarModal}
				/>
			</Box>
		);
	};
}

export default withRouter(CustomerDetails);
